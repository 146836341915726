html,
body {
  background: #f8f9f9 !important;
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  font-family: "Futura PT W01", Century Gothic, Corbel, sans‑serif;
}

html {
  padding: 0;
}

body {
  padding: 0 19px;
  line-height: normal !important; /* Leave it here until we get rid of MUI */
}
